<template>
  <div>
    <dx-util-popup
        ref="planSubscriptionDetailsPopupRef"
        :show-close-button="true"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-title="true"
        :full-screen="false"
        width="35vw"
        height="auto"
        :title="planSubscriptionDetailsPopupTitle"
      >
        <div :key="rerenderKey">
          <div class="row">
            <div class="col-6 mb-1">
              <div class="text-muted">
Plan Name
</div>
              <h5>{{ planDetails.planName }}
                <span class="badge badge-pill text-capitalize px-1 mx-1" :class="resolveStatus(planDetails.status)">{{ planDetails.status }}</span>
              </h5>
            </div>
            <div class="col-6 mb-1">
              <div class="text-muted">
Plan Type
</div>
              <h5>{{ planDetails.planType }}</h5>
            </div>
            <div class="col-6 mb-1">
              <div class="text-muted">
Plan Category
</div>
              <h5 class="text-uppercase">
{{ planDetails.planCategory }}
</h5>
            </div>
            <div class="col-6 mb-1">
              <div class="text-muted">
Minimum Item Number
</div>
              <h5>{{ formattedNumber(planDetails.min) }}</h5>
            </div>
            <div class="col-6 mb-1">
              <div class="text-muted">
Maximum Item Number
</div>
              <h5>{{ formattedNumber(planDetails.max) }}</h5>
            </div>
            <div class="col-6 mb-1">
              <div class="text-muted">
Item Fee
</div>
              <h5>$ {{ currencyFormatter(planDetails.itemFee) }}</h5>
            </div>
            <div class="col-6 mb-1">
              <div class="text-muted">
Discount
</div>
              <h5>{{ planDetails.discount }}</h5>
            </div>
            <div class="col-6 mb-1">
              <div class="text-muted">
Start Date
</div>
              <h5>{{ formatDate(planDetails.startDate) }}</h5>
            </div>
            <div class="col-6 mb-1">
              <div class="text-muted">
Enrollment Date
</div>
              <h5>{{ formatDate(planDetails.enrollmentDate) }}</h5>
            </div>
            <div class="col-6 mb-1">
              <div class="text-muted">
Company Name
</div>
              <h5>{{ planDetails.companyName }}</h5>
            </div>
            <div class="col-6 mb-1">
              <div class="text-muted">
Warehouse
</div>
              <h5>{{ planDetails.warehouse }}</h5>
            </div>
            <div v-if="planDetails.description" class="col-12 mb-1">
              <div class="text-muted">
Description
</div>
              <h5>{{ planDetails.description }}</h5>
            </div>
          </div>
        </div>
    </dx-util-popup>
  </div>
</template>

<script>
import { Notify } from '@/@robustshell/utils'
import planManagementService from '@/http/requests/plan/planManagementService'
import { formatDate, currencyFormatter } from '@core/utils/filter'

export default {
  props: {
    componentId: {
      type: String,
      default: '',
    },
    planId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      planSubscriptionDetailsPopupTitle: 'Plan Details',
      planDetails: {},
      formatDate,
      currencyFormatter,
      rerenderKey: 0,
    }
  },
  computed: {
    planSubscriptionDetailsPopupIns() {
      return this.$refs.planSubscriptionDetailsPopupRef.instance
    },
  },
  watch: {
    planId: {
      immediate: false,
      async handler() {
        if (this.planId) {
          this.getPlanDetails()
        } else {
          Notify.warning('This product does not have any plan')
        }
      },
    },
    componentId: {
      immediate: false,
      handler() {
        if (this.componentId) {
          this.getPlanDetails()
        }
      },
    },
  },
  methods: {
    createPlanSubscriptionDetailsPopup() {
      this.planSubscriptionDetailsPopupIns.show()
    },
    async getPlanDetails() {
      planManagementService
        .fetchUserPlanById(this.planId)
        .then(result => {
          this.planDetails = result.data
          if (!result.data) {
            Notify.warning('Plan details for this plan id does not exists.')
          }
        }).then(() => {
          if (this.planDetails) {
            this.createPlanSubscriptionDetailsPopup()
          }
        })
    },
    resolveStatus(status) {
      return status === 'active' ? 'badge-success' : 'badge-warning'
    },
    formattedNumber(number) {
      return number.toLocaleString()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
